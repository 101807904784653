<!-- 头条资讯--热销推荐 -->
<template>
  <div class="headline-hot">
    <h6 class="h6">热销推荐</h6>
    <div class="img">
      <img src="../../assets/img/headline/hot.png">
    </div>
    <div class="discount">
      <div class="item">
        <p class="p">先领券后购物</p>
        <p class="p1">6/18-6/20</p>
      </div>
      <div class="item">
        <span class="sp">优惠券</span>
        <p class="p">30</p>
        <p class="p1">满299用</p>
        <p class="p2"><span>领取</span></p>
      </div>
      <div class="item">
        <span class="sp">优惠券</span>
        <p class="p">50</p>
        <p class="p1">满299用</p>
        <p class="p2"><span>领取</span></p>
      </div>
      <div class="item">
        <span class="sp">优惠券</span>
        <p class="p">100</p>
        <p class="p1">满299用</p>
        <p class="p2"><span>领取</span></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-hot',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-hot {
  width: 380px;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .img {
    margin-top:35px;
    img {
      display: block;border-radius: 10px;width: 380px;
    }
  }
  .discount {
    zoom:1;width: 380px;margin-top:25px;
    .item {
      float:left;width:calc((100% - 15px)/4);height: 130px;margin-right: 5px;
      &:first-child {
        background: #c80223;border-top-left-radius: 10px;border-bottom-left-radius: 10px;
        .p {
          font-size: 20px;color:#fff;width: 60px;margin:0 auto;margin-top:25px;
        }
        .p1 {
          font-size:12px;color:#fff;border:1px solid #fff;text-align: center;width: 70px;margin:0 auto;margin-top:10px;
        }
      }
      &:nth-child(n+2){
        background: #003d8b;position: relative;overflow: hidden;
        .sp {
          color:#fff;font-size: 12px;text-align: center;display: block;margin-top:5px;
        }
        .p {
          font-size: 42px;color:#fff;text-align: center;
        }
        .p1 {
          font-size: 14px;color:#fff;text-align: center;margin-top:20px;position: relative;
          &:after {
            content: "";display: block;position: absolute;width: 20px;height: 1px;background: #fff;transform: rotate(-45deg);top:-12px;left:35px;
          }
        }
        .p2 {
          font-size: 12px;background: #c80223;text-align: center;position: absolute;width: 90px;height: 18px;line-height: 18px;color:#fff;transform: rotate(-45deg);bottom:0px;right:-35px;
          span {
            transform: scale(0.7);display: block;
          }
        }
      }
      &:last-child {
        border-bottom-right-radius: 10px;border-top-right-radius: 10px;margin-right: 0px;
      }
    }
    &:after {
      content: '';display: block;clear:both;
    }
  }
}
</style>
