<!-- 头条资讯 -->
<template>
  <div class="headline">
	<headlinehead></headlinehead>
	<div class="msgbox">
		<headlinemessage class='headlinemessage'></headlinemessage>
		<headlineact class='headlineact'></headlineact>
	</div>
	<div class="group">
		<headlinenew class='headlinenew'></headlinenew>
		<headlinehot class='headlinehot'></headlinehot>
	</div>
	<headlinemade class='headlinemade'></headlinemade>
  	<imghrs></imghrs>
	<headlinebrd class='headlinebrd'></headlinebrd>
	<div class="group">
		<headlineind class='headlineind'></headlineind>
		<headlinehdhot class='headlinehdhot'></headlinehdhot>
	</div>
  	<div class="likesAuto">
  		<likes class='likes'></likes>
  		<infiniteImg type='0' class='infiniteImg'></infiniteImg>
  	</div>
  </div>
</template>
<script>
import likes from '@/components/likes/likes.vue';//猜你喜欢
import infiniteImg from '@/components/infiniteImg/infiniteImg.vue';//底部滚动展示
import imghrs from '@/components/imghrs/imghrs.vue';//广告banner
import headlinehead from '@/components/headline-head/headline-head.vue';//头条资讯-头部
import headlinemessage from '@/components/headline-message/headline-message.vue';//头条资讯-推荐资讯
import headlineact from '@/components/headline-act/headline-act.vue';//头条资讯-活动聚焦
import headlinenew from '@/components/headline-new/headline-new.vue';//头条资讯-新品推荐
import headlinehot from '@/components/headline-hot/headline-hot.vue';//头条资讯-热销推荐
import headlinemade from '@/components/headline-made/headline-made.vue';//头条资讯-定制案例
import headlinebrd from '@/components/headline-brd/headline-brd.vue';//头条资讯-品牌资讯
import headlineind from '@/components/headline-ind/headline-ind.vue';//头条资讯-行业动态
import headlinehdhot from '@/components/headline-hdhot/headline-hdhot.vue';//头条资讯-头条推荐
export default {
  name: 'headline',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
  	return {
  	  htmltitle:'头条资讯-微微定',
  	  carouselsData:[
  	  	// {src:require('../assets/img/carousel/carousel.jpg')},{src:require('../assets/img/carousel/carousel1.jpg')},{src:require('../assets/img/carousel/carousel2.jpg')},
  	  ],
  	}
  },
  components: {
  	likes,
  	infiniteImg,
  	imghrs,
  	headlinehead,
  	headlinemessage,
  	headlineact,
  	headlinenew,
  	headlinehot,
  	headlinemade,
  	headlinebrd,
  	headlineind,
  	headlinehdhot
  },
  mounted(){
  	this.$nextTick(()=>{
	    // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
  	})
  }
}
</script>
<style lang="scss" scoped>
.headline {
	.msgbox {
		width: 1200px;margin:0 auto;zoom:1;margin-top:40px;
		.headlinemessage {
			float:left;
		}
		.headlineact {
			float:right;
		}
		&:after {
			content: "";display: block;clear: both;
		}
	}
	.group {
		width: 1200px;margin:0 auto;zoom:1;margin-top:30px;padding-bottom:30px;
		.headlinenew {
			float:left;
		}
		.headlinehot {
			float:right;
		}
		.headlineind {
			float:left;
		}
		.headlinehdhot {
			float:right;
		}
		&:after {
			content: "";display: block;clear: both;
		}
	}
	.headlinemade {
		margin-top:20px;
	}
	.headlinebrd {
		margin-top:40px;
	}
	.imghr {
		margin-top:40px;
		a {
			cursor: pointer;
			img {
				display: block;border-radius: 10px;
			}
		}
	}
	.likesAuto {
		width: 1200px;margin:0 auto;margin-top:40px;
		.infiniteImg {
			margin-top:40px;
		}
	}
}
</style>
