<!-- 头条资讯--行业动态 -->
<template>
  <div class="headline-ind">
    <h6 class="h6">行业动态</h6>
    <div class="center">
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/ind.png">
        </div>
        <div class="info">
          十款国风气息满满的折扇
        </div>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/ind1.png">
        </div>
        <div class="info">
          十款国风气息满满的折扇
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-ind',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-ind {
  width: 590px;margin:0 auto;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .center {
    margin-top:35px;zoom:1;
    .item {
      float:left;width: 285px;margin-right: 20px;position: relative;
      .img {
        img {
          display: block;width: 285px;height: 231px;border-radius: 10px;
        }
      }
      .info {
        position: absolute;color:#fff;bottom:0px;left:0px;width: 255px;height: 50px;line-height: 50px;font-size: 18px;padding:0 15px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
    &:after {
      content: '';display: block;clear: both;
    }
  }
}
</style>
