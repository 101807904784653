<!-- 头条资讯--定制案列 -->
<template>
  <div class="headline-made">
    <h6 class="h6">定制案列</h6>
    <div class="center">
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made.png">
        </div>
        <p class="p">餐饮行业</p>
        <p class="p1">Service trade</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made1.png">
        </div>
        <p class="p">美容保健行业</p>
        <p class="p1">Service trade</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made2.png">
        </div>
        <p class="p">娱乐行业</p>
        <p class="p1">Service trade</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made3.png">
        </div>
        <p class="p">教育行业</p>
        <p class="p1">Service trade</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made4.png">
        </div>
        <p class="p">零售行业</p>
        <p class="p1">Service trade</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made5.png">
        </div>
        <p class="p">服务行业</p>
        <p class="p1">Service trade</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made6.png">
        </div>
        <p class="p">金融行业</p>
        <p class="p1">Service trade</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/made7.png">
        </div>
        <p class="p">其他行业</p>
        <p class="p1">Service trade</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-made',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-made {
  width: 1200px;margin:0 auto;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .center {
    margin-top:35px;zoom:1;
    .item {
      width: calc((1200px - 84px)/4);height: calc((1200px - 84px)/4);float:left;border:1px solid #dadada;border-radius: 10px;margin-right: 25px;margin-bottom:25px;
      .img {
        margin-top:45px;
        img {
          display: block;width: 110px;height: 110px;margin:0 auto;
        }
      }
      .p {
        text-align: center;margin-top:35px;font-size: 18px;color:#333;font-weight: bold;
      }
      .p1 {
        text-align: center;font-size: 14px;margin-top:10px;
      }
      &:nth-child(4n+4){
        margin-right: 0px;
      }
    }
    &:after {
      content: '';display: block;clear: both;
    }
  }
}
</style>
