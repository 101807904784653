<!-- 头条资讯--品牌资讯 -->
<template>
  <div class="headline-brd">
    <h6 class="h6">品牌资讯</h6>
    <div class="center">
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/brd.png">
        </div>
        <div class="h5">
          十款国风气息满满的折扇
        </div>
        <p class="p">十款国风气息满满的折扇品牌排名哪一款是品牌排牌排智能折扇品牌排名哪一款是品牌排牌排智能车机有哪些品牌哪一款是品牌排牌排智能车机有哪些品牌哪一款是品牌排牌排智能车机有哪些品牌</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/brd1.png">
        </div>
        <div class="h5">
          十款国风气息满满的折扇
        </div>
        <p class="p">十款国风气息满满的折扇品牌排名哪一款是品牌排牌排智能折扇品牌排名哪一款是品牌排牌排智能车机有哪些品牌哪一款是品牌排牌排智能车机有哪些品牌哪一款是品牌排牌排智能车机有哪些品牌</p>
      </div>
      <div class="item">
        <div class="img">
          <img src="../../assets/img/headline/brd2.png">
        </div>
        <div class="h5">
          十款国风气息满满的折扇
        </div>
        <p class="p">十款国风气息满满的折扇品牌排名哪一款是品牌排牌排智能折扇品牌排名哪一款是品牌排牌排智能车机有哪些品牌哪一款是品牌排牌排智能车机有哪些品牌哪一款是品牌排牌排智能车机有哪些品牌</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-brd',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-brd {
  width: 1200px;margin:0 auto;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .center {
    margin-top:35px;zoom:1;
    .item {
      float:left;width: 380px;margin-right: 20px;
      .img {
        img {
          display: block;border-radius: 10px;width: 380px;height: 306px;
        }
      }
      .h5 {
        height: 50px;line-height: 50px;font-size: 20px;
      }
      .p {
        width: 380px;font-size: 14px;color:#777; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
    &:after {
      content: '';display: block;clear:both;
    }
  }
}
</style>
