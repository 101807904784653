<!-- 头条资讯--活动聚焦 -->
<template>
  <div class="headline-act">
    <h6 class="h6">活动聚焦</h6>
    <div class="imgbox">
      <div class="img">
        <img src="../../assets/img/headline/act.png">
      </div>
      <div class="actmsg">
        <p class="p">
          <span class="lt"></span>
          月度拔河
          <span class="sp">Monthly tug of war</span>
          <span class="rt"></span>
        </p>
      </div>
    </div>
    <div class="msglist">
      <div class="item" v-for='item,index in msglist' :key='index'>
        <div class="txt">
          <p class="sp">{{item.title}}</p>
          <p class="sprt">
            {{item.time}}
          </p>
          <span class="tip"><i>活动</i></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-act',
  props: {
    msg: String
  },
  data(){
    return {
      msglist:[
        {time:'2019/10/08',title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
        {time:'2019/10/08',title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
        {time:'2019/10/08',title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
        {time:'2019/10/08',title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
        {time:'2019/10/08',title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
      ]
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-act {
  width: 380px;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .imgbox {
    margin-top:35px;position: relative;
    .img {
      img {
        display: block;width: 380px;height: 300px;border-radius: 10px;
      }
    }
    .actmsg {
      width: 380px;height: 300px;position: absolute;top:0px;left:0px;background: rgba(0,0,0,0.5);border-radius: 10px;
      .p {
        width: 260px;height: 40px;margin:0 auto;color:#fff;margin-top:125px;text-align: center;font-size: 20px;position: relative;
        .sp {
          display: block;font-size: 12px;
        }
        .lt {
          position: absolute;width: 75px;height: 40px;display: block;top:0px;left:0px;
          &:after {
            content: "";display: block;position: absolute;width: 60px;height: 1px;background: #fff;left:0px;top:20px;
          }
          &:before {
            content: "";display: block;position: absolute;width: 45px;height: 1px;background: #fff;transform: rotate(-75deg);right:-8px;top:21px;
          }
        }
        .rt {
          position: absolute;width: 75px;height: 40px;display: block;top:0px;right:0px;
          &:after {
            content: "";display: block;position: absolute;width: 60px;height: 1px;background: #fff;right:0px;top:20px;
          }
          &:before {
            content: "";display: block;position: absolute;width: 45px;height: 1px;background: #fff;transform: rotate(-75deg);left:-8px;top:21px;
          }
        }
      }
    }
  }
  .msglist {
    margin-top:10px;
    .item {
      margin-bottom:5px;zoom:1;
      .txt {
        float:left;padding-left:20px;height: 40px;line-height: 40px;background: #eaeaea;width: 360px;position: relative;overflow: hidden;
        .sp {
          display: block;width: 230px;font-size: 14px;color:#303030;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;float:left;
        }
        .sprt {
          float:right;color:#858585;font-size: 12px;padding-right: 10px;position: relative;
        }
        .tip {
          position: absolute;display: block;background: #ab1d29;height: 15px;width: 60px;line-height: 15px;font-size: 12px;color:#fff;text-align: center;left:-22px;top:1px;transform: rotate(-45deg);
          i {
            font-style: normal;font-size: 12px;transform: scale(0.7);display: block;
          }
        }
      }
      &:after {
        content: "";display: block;clear:both;
      }
    }
  }
}
</style>
