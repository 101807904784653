<!-- 头条资讯--推荐资讯 -->
<template>
  <div class="headline-message">
    <h6 class="h6">推荐资讯</h6>
    <div class="list">
      <div class="item" v-for='item,index in list' :key='index'>
        <div class="img">
          <img :src="item.url">
        </div>
        <div class="info">
          <p>{{item.title}}</p>
        </div>
      </div>
    </div>
    <div class="msglist">
      <div class="item" v-for='item,index in msglist' :key='index'>
        <p class="title">
          头条
          <span class="lt"></span>
          <span class="rt"></span>
        </p>
        <div class="txt">
          <p class="sp">{{item.title}}</p>
          <p class="sprt">
            {{item.num}}
            <svg><use xlink:href="#icondianzan"></use></svg>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-message',
  props: {
    msg: String
  },
  data(){
    return {
      list:[
        {link:'www.baidu.com',url:require('../../assets/img/headline/msg.png'),title:'微微数码9月员工生日会现场记录'},
        {link:'www.baidu.com',url:require('../../assets/img/headline/msg1.png'),title:'8月员工生日会'},
        {link:'www.baidu.com',url:require('../../assets/img/headline/msg2.png'),title:'7月员工生日会'},
        {link:'www.baidu.com',url:require('../../assets/img/headline/msg3.png'),title:'6月员工生日会'},
        {link:'www.baidu.com',url:require('../../assets/img/headline/msg4.png'),title:'5月员工生日会'},
      ],
      msglist:[
        {num:123,title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
        {num:1223,title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
        {num:123,title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
        {num:1223,title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
        {num:123,title:'折耳猫吃什么牌子的猫粮好 猫粮十大品折耳猫吃什么牌子的猫粮好猫粮十大品折耳猫吃什么牌子的猫粮好'},
      ]
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-message {
  width: 800px;
  .h6 {
    font-size: 20px;position: relative;
    &:after {
      content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
    }
  }
  .list {
    zoom:1;margin-top:35px;
    .item {
      float:left;position: relative;
      .img {
        img {
          display: block;
        }
      }
      &:nth-child(1) {
        .img {
          width: 480px;height: 300px;
          img {
            width: 480px;height: 300px;
          }
        }
        .info {
          position: absolute;width:480px;height: 50px;bottom:0px;left:0px;background: rgba(0,0,0,0.6);
          p {
            font-size: 24px;color:#fff;line-height: 50px;margin-left:10px;
          }
        }
      }
      &:nth-child(n+2) {
        .img {
          width: 150px;height: 146px;margin-left:10px;margin-bottom:10px;
          img {
            width: 150px;height: 146px;
          }
        }
        .info {
          position: absolute;width: 150px;height: 30px;bottom:10px;left:10px;background: rgba(0,0,0,0.6);
          p {
            font-size: 16px;color:#fff;line-height: 30px;margin-left:10px;
          }
        }
      }
    }
    &:after {
      content: "";display: block;clear:both;
    }
  }
  .msglist {
    .item {
      margin-bottom:5px;zoom:1;
      .title {
        background: #ab1d29;width: 50px;height: 40px;line-height: 40px;text-align: center;color:#fff;position: relative;font-size: 14px;float:left;margin-left:15px;
        .lt {
          position: absolute;width: 15px;height: 40px;top:0px;left:-15px;overflow: hidden;
          &:after {
            content: "";display: block;position: absolute;width: 60px;height: 30px;background: #ab1d29;top:-1px;left:3px;transform:rotate(-35deg);
          }
          &:before {
            content: "";display: block;position: absolute;width: 60px;height: 30px;background: #ab1d29;top:11px;left:3px;transform:rotate(40deg);
          }
        }
        .rt {
          position: absolute;width: 15px;height: 40px;top:0px;right:-15px;overflow: hidden;
          &:after {
            content: "";display: block;position: absolute;width: 60px;height: 30px;background: #ab1d29;top:0px;left:-45px;transform:rotate(-50deg);
          }
          &:before {
            content: "";display: block;position: absolute;width: 60px;height: 30px;background: #ab1d29;top:9px;left:-46px;transform:rotate(50deg);
          }
        }
      }
      .txt {
        float:left;padding-left:30px;height: 40px;line-height: 40px;background: #eaeaea;width: 705px;
        .sp {
          display: block;width: 510px;font-size: 14px;color:#303030;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;float:left;
        }
        .sprt {
          float:right;color:#858585;font-size: 14px;padding-right: 10px;
          svg {
            fill: #858585;width: 16px;height: 16px;vertical-align: -1px;margin-left:5px;
          }
        }
      }
      &:after {
        content: "";display: block;clear:both;
      }
    }
  }
}
</style>
